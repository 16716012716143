/*
 Order of the layers.
 first is the least powerfull
 last one is the most powerfull
*/
@layer application-resets;
@layer primeng;
@layer app-primeng-reset;
@layer primeng-utility;
@layer application-component;
