@layer app-primeng-reset {
    .p-tabview-panels {
        padding: 0;
    }

    .p-inputnumber {
        inline-size: 100%;
    }

    .p-inputnumber-input {
        inline-size: 100%;
    }

    .info-icon,
    .info-icon-large {
        font-size: 1.125rem;
        color: theme(colors.r1-blue.800);
    }

    .p-dropdown,
    .p-multiselect {
        block-size: 2.9rem;
        inline-size: 100%;
    }

    .p-component {
        @apply text-sm;
    }
}