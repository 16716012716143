@layer primeng-utility {
  /* 
     set this class "res-edit-preview-tab-view" to p-tabView element
     like <p-tabView styleClass="res-edit-preview-tab-view"></p-tabView>
     in this case tabs will change the style. Tabs will not have gaps
     between buttons. And other styles will be applied.
     Example can be observed on "Document Creation" and "Packaging" tabs
     where you can find "Edit Mode" and "Preview Mode" buttons.    
    */

  .res-edit-preview-tab-view {
    .p-tabview-nav-content {
      display: grid;
      justify-content: end;

      .p-tabview-nav {
        border: none;

        li:first-child .p-button {
          border-start-start-radius: 0.625rem;
          border-end-start-radius: 0.625rem;
        }

        /* selects last <li> element from list excluding hidden <li> elements.
                PrimeNG by default adds dummy hidden <li> in the end of the list. So we need to skip it. */
        li:nth-last-child(-n + 1 of li:not([aria-hidden='true'])) .p-button {
          border-start-end-radius: 0.625rem;
          border-end-end-radius: 0.625rem;
        }

        .p-button {
          background-color: theme(colors.white);
          border-color: theme(colors[gray].950);
          color: theme(colors[gray].950);
          border-radius: 0;

          &:focus {
            box-shadow: none;
          }
        }

        .p-highlight {
          .p-button {
            background-color: theme(colors[gray].950);
            border-color: theme(colors[gray].950);
            color: theme(colors.white);
          }
        }

        .p-tabview-nav-link {
          padding: 0;
          border-start-end-radius: 0;
          border-start-start-radius: 0;
        }
      }
    }
  }

  .res-p-message {
    .p-message {
      border-radius: initial;
      margin: 0;

      .p-message-wrapper {
        padding-block: 13px;

        .p-message-icon .p-icon {
          inline-size: 1rem;
          block-size: 1rem;
        }
      }
    }
  }

  .res-dropdown-width-to-parent-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;

    .p-dropdown-label {
      width: initial;
    }
  }

  .res-accordion-sticky-header {
    .p-accordion-header {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}
