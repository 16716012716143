@font-face {
  font-family: 'campton-book';
  src: url('Campton-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'campton-light';
  src: url('Campton-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'campton-medium';
  src: url('Campton-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'campton-semibold';
  src: url('Campton-SemiBold.woff2') format('woff2');
}
