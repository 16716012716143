@import 'styles/layered-styles/primeng-resets.css';
@import 'styles/layered-styles/application-resets.css';
@import 'styles/layered-styles/primeng-utility.css';
@import 'primeicons/primeicons.css';
@import 'styles/unlayered-styles/ag-grid-resets.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    /*
        There is no css property to set scrollbar width in PX.
        Only some keywords like "thin". Every browser can have
        own size for "thin" scrollbar. So below is an
        approximated size of the "thin" scrollbar which at least
        works well for Chrome and Edge.
        This variable was created because if we use vh, vw units
        they don't take into account the size of the scrollbar.
        So that if you set something like:
            body { min-height: 100vh; }
        It will work unless something overflows the container.
        And when it happen, the size of the scrollbar itself
        will force the browser to create additional scrollbar
        but horizontal.
        TODO (gorodnichev): use JS to adjust this css variable
        with correct width of the scrollbar.            
    */
    --res-scrollbar-width: 0.7rem;
    --res-header-cloudmed-height: 56px;
  }

  html {
    @apply font-normal text-gray-900;
  }

  body {
    @apply text-sm;
  }

  ::spelling-error {
    text-decoration: wavy theme(colors.red.600) underline;
  }

  ::highlight(res-app-highlighter-1),
  ::highlight(res-app-highlighter-2),
  ::highlight(res-app-highlighter-3),
  ::highlight(res-app-highlighter-4),
  ::highlight(res-app-highlighter-5),
  ::highlight(res-app-highlighter-6),
  ::highlight(res-app-highlighter-7),
  ::highlight(res-app-highlighter-8) {
    background-color: theme(colors.orange.50);
    text-decoration: solid theme(colors.r1-blue.800) underline;
  }
}

@layer components {
  .text-body {
    @apply text-sm font-normal;
  }

  .text-label {
    @apply text-sm font-semibold;
  }

  .text-section-header {
    @apply text-base font-semibold;
  }

  .text-subheader {
    @apply text-lg font-semibold;
  }

  .text-header {
    @apply text-xl font-semibold;
  }

  .text-display {
    @apply text-2xl font-semibold;
  }
}
